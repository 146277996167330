<template>
    <div>

        <el-menu class="nav-links2" router>
            <el-menu-item index="products" class="nav-item" >公司产品</el-menu-item>
            <el-menu-item index="lpcontrol" class="nav-item" >土壤污染治理</el-menu-item>
            <el-menu-item index="biogasproject" class="nav-item" >沼气工程</el-menu-item>


            <el-menu-item index="cleanerproduction" class="nav-item" >清洁生产</el-menu-item>
            <el-menu-item index="wgpcontrol" class="nav-item" >废气治理</el-menu-item>
            <el-menu-item index="wwtreatment" class="nav-item" >废水处理</el-menu-item>
            <el-menu-item index="consultingevaluation" class="nav-item" >咨询评估</el-menu-item>

            <el-menu-item index="technologyconsulting" class="nav-item" >环保技术咨询服务</el-menu-item>
            <el-menu-item index="polypropyleneequipment" class="nav-item">聚丙烯设备</el-menu-item>
            <el-menu-item index="dirtcatcher" class="nav-item">除尘器</el-menu-item>
            <el-menu-item index="recommend" class="nav-item " >推荐产品</el-menu-item>
            <el-menu-item index="polypropyleneaequipment" class="nav-item " >聚丙烯 (PP) 防腐设备</el-menu-item>
            <el-menu-item index="slpaequipment" class="nav-item " >钢衬塑防腐设备</el-menu-item>

        </el-menu>

        <div class="news-container" style="margin-top: 20px;margin-left: -10px;">
            <router-link to="/text">
                <el-tag type="info" class="custom-tag">新闻资讯</el-tag>
            </router-link>
            <div class="news-list">
                <div v-for="(news, index) in newsList" :key="index" class="news-item">
                    <router-link :to="{ path: '/textdetails', query: { news: news } }" class="custom-link">

                        <div class="bullet"></div>
                        <div class="news-content">{{ getLimitedNews(news) }}</div>
                    </router-link>
                </div>
            </div>

        </div>

    </div>

</template>

<script>

    import request from "../utils/request";

    export default {
        name: "Aside",
        data() {
            return {
                newsList: [],
                maxCharacters: 10,
            }

        },
        created() {
            this.fetchnewsList();
        },
        methods: {
            navigateTo(routeName, category) {
                this.$router.push({ name: routeName, query: { category } });
            },
            fetchnewsList(){
                request.get("/text/getnewstitle").then(res => {
                    console.log(res)
                    this.newsList = res.data
                })
            },
            getLimitedNews(news) {
                if (news.length > this.maxCharacters) {
                    return news.slice(0, this.maxCharacters) + '...';
                }
                return news;
            },
        },
    }


</script>

<style scoped>


    .nav-links2 {
        height: 730px;
        width: 200px;
        margin-left: 5px;
        background: #4767b9;
        border-radius: 20px; /* 设置圆角大小，根据需要调整 */
        overflow: hidden; /* 防止圆角溢出 */
    }

    /* 修改导航栏的字体颜色为白色 */
    .nav-links2 .nav-item {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* 修改导航栏激活状态的字体颜色为白色 */
    .nav-links2 .nav-item.is-active {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* 修改子菜单打开后的背景颜色 */
    .el-submenu.is-opened .el-menu {
        background-color: #4767b9;
    }

    /* 修改子菜单打开后的文字颜色 */
    .el-submenu.is-opened .el-menu-item {
        color: white;
    }

    .news-container {
        height: 400px;
        width: 225px;
        display: flex; /* 使用flex布局 */
        flex-direction: column;
        justify-content: center; /* 设置子元素水平居中 */
        align-items: center; /* 设置子元素垂直居中 */
    }

    .custom-tag{
        width: 225px;
        background-color: #4767b9;
        color: #ffffff;
        font-size: 20px;
        padding: 20px;
    }


    .news-list {
        margin-top: 20px;
    }

    .news-item {

        display: flex;
        align-items: center;
        margin-bottom: 13px;
        border-bottom: 1px dashed #ccc; /* 添加虚线样式 */
        padding-bottom: 10px; /* 调整虚线与内容的间隔 */
        position: relative; /* Add position relative to the .news-item */
    }

    .custom-link {
        /* 去除超链接的下划线 */
        text-decoration: none;
        /* 设置链接的文字颜色 */
        color: #333;
        /* 可添加其他样式，如字体大小、背景色等 */
    }

    .bullet {
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background-color: #4767b9; /* 可根据需求自行调整颜色 */
        margin-right: 10px;
        position: absolute; /* Add position absolute to the .bullet */
        top: 40%; /* Position the bullet in the middle vertically */
        transform: translateY(-50%); /* Adjust vertical position */


    }

    .news-content {
        white-space: nowrap; /* 防止内容换行 */
        overflow: hidden; /* 隐藏溢出的内容 */
        text-overflow: ellipsis; /* 显示省略号 */
        /*border-left: 1px dashed #ccc; !* 虚线间隔样式 *!*/
        padding-left: 10px;
        font-size: 18px; /* 调整文字大小 */
        line-height: 1.6; /* 调整行高，实现每行间距 */
        font-family: '楷体', sans-serif; /* 设置字体样式 */
    }


</style>