import {createRouter, createWebHistory} from 'vue-router'
import Layout from '../layout/Layout.vue'

const routes = [
    {
        path: '/',
        name: 'Layout',
        component: Layout,
        redirect: "/home",
        children: [
            {
                path: 'user',
                name: 'User',
                component: () => import("../views/User"),
            },
            {
                path: '/userinfor',
                name: 'UserInfor',
                component: () => import("../views/UserInfor"),
            },
            {
                path: '/travel',
                name: 'Travel',
                component: () => import("../views/Travel"),
            },
            {
                path: '/news',
                name: 'News',
                component: () => import("../views/News"),
            },
            {
                path: '/home',
                name: 'Home',
                component: () => import("../views/Home"),
            },
            {
                path: '/products',
                name: 'Products',
                component: () => import("../views/Products"),
            },
            {
                path: '/text',
                name: 'Text',
                component: () => import("../views/Text"),
            },
            {
                path: '/evaluation',
                name: 'Evaluation',
                component: () => import("../views/Evaluation"),
            },
            {
                path: '/performance',
                name: 'Performance',
                component: () => import("../views/Performance"),
            },
            {
                path: '/productsdetails',
                name: 'ProductsDetails',
                component: () => import("../views/ProductsDetails"),
            },
            {
                path: '/textdetails',
                name: 'TextDetails',
                component: () => import("../views/TextDetails"),
            },
            {
                path: '/evaluationdetails',
                name: 'EvaluationDetails',
                component: () => import("../views/EvaluationDetails"),
            },
            {
                path: '/performancedetails',
                name: 'PerformanceDetails',
                component: () => import("../views/PerformanceDetails"),
            },
            {
                path: '/aboutus',
                name: 'AboutUs',
                component: () => import("../views/AboutUs"),
            },
            {
                path: '/contactus',
                name: 'ContactUs',
                component: () => import("../views/ContactUs"),
            },
            {
                path: '/invite',
                name: 'Invite',
                component: () => import("../views/Invite"),
            },
            {
                path: '/invite',
                name: 'Invite',
                component: () => import("../views/Invite"),
            },
            {
                path: '/lpcontrol',
                name: 'LPControl',
                component: () => import("../views/ProductsClassification/LPControl"),
            },
            {
                path: '/biogasproject',
                name: 'BiogasProject',
                component: () => import("../views/ProductsClassification/BiogasProject"),
            },
            {
                path: '/cleanerproduction',
                name: 'CleanerProduction',
                component: () => import("../views/ProductsClassification/CleanerProduction"),
            },
            {
                path: '/dirtcatcher',
                name: 'DirtCatcher',
                component: () => import("../views/ProductsClassification/DirtCatcher"),
            },
            {
                path: '/polypropyleneaequipment',
                name: 'PolypropyleneAEquipment',
                component: () => import("../views/ProductsClassification/PolypropyleneAEquipment"),
            },
            {
                path: '/polypropyleneequipment',
                name: 'PolypropyleneEquipment',
                component: () => import("../views/ProductsClassification/PolypropyleneEquipment"),
            },
            {
                path: '/recommend',
                name: 'Recommend',
                component: () => import("../views/ProductsClassification/Recommend"),
            },
            {
                path: '/technologyconsulting',
                name: 'TechnologyConsulting',
                component: () => import("../views/ProductsClassification/TechnologyConsulting"),
            },
            {
                path: '/wgpcontrol',
                name: 'WGPControl',
                component: () => import("../views/ProductsClassification/WGPControl"),
            },
            {
                path: '/wwtreatment',
                name: 'WWTreatment',
                component: () => import("../views/ProductsClassification/WWTreatment"),
            },
            {
                path: '/consultingevaluation',
                name: 'ConsultingEvaluation',
                component: () => import("../views/ProductsClassification/ConsultingEvaluation"),
            },
            {
                path: '/slpaequipment',
                name: 'SLPAEquipment',
                component: () => import("../views/ProductsClassification/SLPAEquipment"),
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import("../views/Login"),
    },

    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


export default router
