<template>
    <div>
        <router-view/>
    </div>


</template>

<style>


</style>

<script>

    export default {
        name: "App"
    }
</script>
