<template>
    <div style="text-align: center;" class="page-container">
        <!--        头部-->
        <Header/>
        <!--        <div style="display: flex ;display: inline-block; align-items: flex-start;">-->
        <!--            <el-card style="width: 1500px; display: inline-block; text-align: left;">-->
        <!--                &lt;!&ndash; 侧边栏 &ndash;&gt;-->
        <!--                <Aside v-if="showAside"/>-->
        <!--            </el-card>-->
        <!-- 内容区域 -->
<!--        <div style="width: 1500px;text-align: left; display: inline-block;">-->
<!--            <Aside v-if="showAside" style="display: inline-block;"/>-->
            <router-view @userInfo="refreshUser" style="display: inline-block;"/>
<!--        </div>-->

    </div>
</template>

<script>
    import Header from "../components/Header";
    import Aside from "../components/Aside";
    import request from "../utils/request";

    export default {
        name: "Layout",
        components: {
            Aside,
            Header
        },
        data() {

            return {
                showAside: true, // 初始状态为显示侧边栏
            };

        },
        created() {

            // // 判断当前路由是否为 /home，如果是则将侧边栏隐藏
            // this.showAside = this.$route.path !== "/home";
        },
        // watch: {
        //     // 监听路由变化
        //     $route(to, from) {
        //         // 判断当前路由是否为 /home，如果是则将侧边栏隐藏
        //         this.showAside = to.path !== "/home";
        //     }
        // },

        methods: {
            refreshUser() {
                let userJson = sessionStorage.getItem("user")
                if (!userJson) {
                    return
                }
                let userId = JSON.parse(userJson).id
                // 从后台取出更新后的最新信息
                request.get("/user/" + userId).then(res => {
                    this.user = res.data
                })
            }
        }
    }
</script>

<style>
    .page-container {
        margin-top: -90px;
        background-image: url(../assets/img/background.png);
        background-repeat: repeat;
        background-size: auto;
        /* 可以调整背景图片的填充方式，cover 会保持宽高比例并且填充整个容器，contain 会保持宽高比例并且完全显示图片在容器中 */
    }
</style>