<template>
    <div style="height: 500px;width: 100%; line-height:50px;  display:flex">
        <el-header class="navbar" style="margin-top: 0;">
            <div class="logo" style="margin-top: 100px">
                <img style="height: 100px; max-height: 100px;" src="@/assets/img/logo.png" alt="Logo">
            </div>

            <div class="menu-container" style="padding-right: 19px">

                <el-menu class="nav-links" mode="horizontal" router>
                    <el-menu-item index="home" class="nav-item">首页</el-menu-item>
                    <el-menu-item index="aboutus" class="nav-item">走进智方</el-menu-item>
                    <el-menu-item index="products" class="nav-item">产品展示</el-menu-item>
                    <el-menu-item index="text" class="nav-item">行业资讯</el-menu-item>
                    <el-menu-item index="evaluation" class="nav-item">咨询评估</el-menu-item>
                    <el-menu-item index="performance" class="nav-item">公司业绩</el-menu-item>
                    <el-menu-item index="contactus" class="nav-item">联系我们</el-menu-item>
                    <el-menu-item index="invite" class="nav-item">企业人才招聘</el-menu-item>

                </el-menu>
            </div>
            <div style="height: 10px; min-height: 10px;width: 1500px;background: linear-gradient(to right, #dcc985, #e3bb00 50%, #dcc985);"> </div>
            <div>
                <el-carousel class="nav-carousel" :interval="4000">
                    <el-carousel-item>
                        <img src="@/assets/img/carousel1.png" alt="carousel1" style="width: 100%; height: 300px;">
                    </el-carousel-item>
                    <el-carousel-item>
                        <img src="@/assets/img/carousel2.png" alt="carousel2" style="width: 100%; height: 300px;">
                    </el-carousel-item>
                </el-carousel>


            </div>

        </el-header>

    </div>


</template>

<script>

    export default {
        name: 'Header',
        // 代替了data里面的 为实现组建联动
        data() {
            return {};
        }
    }

</script>


<style scoped>
    .navbar {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px;
        background-color: #f0f0f0;
    }

    .logo {
        font-size: 20px;
        font-weight: bold;
    }

    .nav-links {
        background: #4767b9;
        height: 80px;
        width: 1500px;
        margin-left: 20px;
        border: 1.5px solid #aebbca; /* 添加微小外边框 */
        border-radius: 10px; /* 添加圆角 */
        border-bottom-left-radius: 0; /* 去掉左上角圆角 */
        border-bottom-right-radius: 0; /* 去掉右上角圆角 */
    }

    .menu-container {

        display: flex;
        flex-direction: column; /* 将菜单项垂直排列 */
        justify-content: center; /* 将内容垂直居中 */
        align-items: center; /* 将内容水平居中 */
    }

    .el-menu-item {
        margin-right: 10px;
    }

    /* 修改导航栏的字体颜色为白色 */
    .nav-links .nav-item {
        color: white;
    }

    /* 修改导航栏激活状态的字体颜色为白色 */
    .nav-links .nav-item.is-active {
        color: white;
    }

    .nav-carousel {
        /*margin-top: 10px;*/
        height: 300px;
        width: 1500px;
    }


</style>
